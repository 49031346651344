import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import axiosInstance from '../utils/axiosConfig';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxHeight: '80vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const AddUserModal = ({ open, handleClose, handleAdd }) => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    role_id: '',
    office_id: '',
  });

  const [roles, setRoles] = useState([]);
  const [offices, setOffices] = useState([]);

  // Загрузка ролей
  const fetchRoles = async () => {
    try {
      const response = await axiosInstance.get('/roles');
      setRoles(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке ролей:', error);
    }
  };

  // Загрузка офисов
  const fetchOffices = async () => {
    try {
      const response = await axiosInstance.get('/offices');
      setOffices(response.data);
    } catch (error) {
      console.error('Ошибка при загрузке офисов:', error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchRoles();
      fetchOffices();
    }
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = ['role_id', 'office_id'].includes(name) ? Number(value) : value;
    setFormData({ ...formData, [name]: newValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAdd(formData);
  };

  const handleReset = () => {
    setFormData({
      username: '',
      email: '',
      password: '',
      role_id: '',
      office_id: '',
    });
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-user-modal-title"
      aria-describedby="add-user-modal-description"
    >
      <Box sx={style} component="form" onSubmit={handleSubmit}>
        <Typography id="add-user-modal-title" variant="h6" gutterBottom>
          Добавить Пользователя
        </Typography>

        {/* Логин */}
        <TextField
          fullWidth
          label="Логин (Username)"
          name="username"
          value={formData.username}
          onChange={handleChange}
          margin="normal"
          required
        />

        {/* Email */}
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          margin="normal"
          required
        />

        {/* Пароль */}
        <TextField
          fullWidth
          label="Пароль"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
          margin="normal"
          required
        />

        {/* Роль */}
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="role-label">Роль</InputLabel>
          <Select
            labelId="role-label"
            name="role_id"
            value={formData.role_id}
            label="Роль"
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Выберите роль</em>
            </MenuItem>
            {roles.map((role) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Офис */}
        <FormControl fullWidth margin="normal" required>
          <InputLabel id="office-label">Офис</InputLabel>
          <Select
            labelId="office-label"
            name="office_id"
            value={formData.office_id}
            label="Офис"
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Выберите офис</em>
            </MenuItem>
            {offices.map((office) => (
              <MenuItem key={office.id} value={office.id}>
                {office.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Кнопки */}
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button onClick={handleReset} color="secondary" sx={{ mr: 2 }}>
            Отмена
          </Button>
          <Button variant="contained" type="submit">
            Добавить
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddUserModal;
