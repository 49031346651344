import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

const AddOfficeModal = ({ open, onClose, onOfficeAdded }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const { user } = useAuth(); 

  const handleAddOffice = async () => {
    setError('');

    if (!name) {
      setError('Название офиса обязательно');
      return;
    }

    try {
      const token = localStorage.getItem('token');

      const response = await axios.post(
        'http://localhost:5000/api/business-units/call-centers',
        {
          name,
          userId: user.id, 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      onOfficeAdded(response.data.office);
      setName('');
      onClose(); 
    } catch (error) {
      console.error('Ошибка создания офиса:', error);
      setError('Не удалось создать офис');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'relative',
          p: 4,
          backgroundColor: 'white',
          width: 400,
          borderRadius: 2,
          boxShadow: 24,
          margin: 'auto',
          mt: 10,
          textAlign: 'center',
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" gutterBottom>
          Создать новый офис
        </Typography>
        {error && (
          <Typography color="error" variant="body2" gutterBottom>
            {error}
          </Typography>
        )}
        <TextField
          label="Название офиса"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={handleAddOffice}>
            Создать офис
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Отмена
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddOfficeModal;
