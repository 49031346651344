import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './AddLeadModal.css';

const AddLeadModal = ({ onClose, onAddLead }) => {
    const initialValues = {
        surname: '',
        first_name: '',
        patronymic: '',
        phone_number: '',
        email: '',
        country: '',
    };

    // Схема валидации с yup
    const validationSchema = Yup.object({
        surname: Yup.string().required('Фамилия обязательна'),
        first_name: Yup.string().required('Имя обязательно'),
        patronymic: Yup.string().required('Отчество обязательно'),
        phone_number: Yup.string()
            .matches(/^\d{10,11}$/, 'Номер должен содержать от 10 до 11 цифр')
            .required('Номер телефона обязателен'),
        email: Yup.string().email('Неверный формат email').required('Email обязателен'),
        country: Yup.string().required('Страна обязательна'),
    });

    const handleSubmit = (values, { resetForm }) => {
        onAddLead(values);
        resetForm();
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close-btn" onClick={onClose}>&times;</button>
                <h2 className="modal-title">Добавить нового лида</h2>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form className="add-lead-form">
                        <div className="form-group">
                            <label>Фамилия</label>
                            <Field type="text" name="surname" />
                            <ErrorMessage name="surname" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label>Имя</label>
                            <Field type="text" name="first_name" />
                            <ErrorMessage name="first_name" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label>Отчество</label>
                            <Field type="text" name="patronymic" />
                            <ErrorMessage name="patronymic" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label>Номер телефона</label>
                            <Field type="text" name="phone_number" />
                            <ErrorMessage name="phone_number" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label>Email</label>
                            <Field type="email" name="email" />
                            <ErrorMessage name="email" component="div" className="error-message" />
                        </div>

                        <div className="form-group">
                            <label>Страна</label>
                            <Field type="text" name="country" />
                            <ErrorMessage name="country" component="div" className="error-message" />
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn-cancel" onClick={onClose}>
                                Отмена
                            </button>
                            <button type="submit" className="btn-submit">
                                Добавить Лид
                            </button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default AddLeadModal;
