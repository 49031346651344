// src/components/Header/Header.js

import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Menu, MenuItem, Tooltip, Badge, Switch, FormControlLabel, Select, FormControl } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ProfileModal from '../ProfileModal/ProfileModal';
import { Brightness4, Brightness7 } from '@mui/icons-material';

const Header = ({ themeMode, toggleTheme, language, setLanguage }) => {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(5); // Пример уведомлений

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
    handleProfileMenuClose();
  };

  const handleLogoutClick = () => {
    try {
      logout();
      navigate('/login');
    } catch (error) {
      console.error("Ошибка при выходе:", error);
    }
  };

  const handleModalClose = () => {
    setIsProfileModalOpen(false);
  };

  if (!auth.token) {
    return null; // Показываем только, если пользователь авторизован
  }

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Nexelance CRM
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Переключатель темы */}
            <Tooltip title={themeMode === 'dark' ? 'Светлая тема' : 'Тёмная тема'}>
              <IconButton onClick={toggleTheme} color="inherit">
                {themeMode === 'dark' ? <Brightness7 /> : <Brightness4 />}
              </IconButton>
            </Tooltip>
            {/* Переключатель языка */}
            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <Select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                displayEmpty
                inputProps={{ 'aria-label': 'Сменить язык' }}
              >
                <MenuItem value="ru">Русский</MenuItem>
                <MenuItem value="en">English</MenuItem>
              </Select>
            </FormControl>
            {/* Иконка уведомлений */}
            <Tooltip title="Уведомления">
              <IconButton size="large" color="inherit">
                <Badge badgeContent={notificationCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            {/* Имя пользователя и профиль */}
            <Tooltip title="Профиль">
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                onClick={handleProfileMenuOpen}
                sx={{ ml: 2 }}
              >
                <AccountCircleIcon />
                <Typography variant="body1" sx={{ ml: 1 }}>
                  {auth.user?.username || 'Пользователь'}
                </Typography>
              </IconButton>
            </Tooltip>
            {/* Меню профиля */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleProfileMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={handleProfileClick}>Мой профиль</MenuItem>
              <MenuItem onClick={handleLogoutClick}>
                <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
                Выйти
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <ProfileModal open={isProfileModalOpen} handleClose={handleModalClose} />
    </>
  );
};

export default Header;
