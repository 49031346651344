import React, { useState, useEffect } from 'react';
import './FilterModal.css';
import axios from 'axios';

const FilterModal = ({ onClose, onApplyFilters }) => {
    const [activeTab, setActiveTab] = useState('columns');
    const [filters, setFilters] = useState({});
    const [filterOptions, setFilterOptions] = useState({
        countries: [],
        statuses: [],
        agents: []
    });
    const [selectedAgent, setSelectedAgent] = useState('');

    useEffect(() => {
        const fetchFilterOptions = async () => {
            try {
                const response = await axios.get('/api/leads/filters');
                setFilterOptions(response.data);
            } catch (error) {
                console.error('Ошибка при загрузке опций фильтра:', error);
            }
        };

        fetchFilterOptions();
    }, []);

    const handleColumnFilterChange = (column, value) => {
        setFilters((prevFilters) => {
            const columnFilters = prevFilters[column] || [];
            const newColumnFilters = columnFilters.includes(value)
                ? columnFilters.filter((v) => v !== value)
                : [...columnFilters, value];
            return { ...prevFilters, [column]: newColumnFilters };
        });
    };

    const handleApplyFilters = () => {
        onApplyFilters(filters);
    };

    return (
        <div className="filter-modal-overlay">
            <div className="filter-modal">
                <h2>Фильтры</h2>

                <div className="tab-menu">
                    <button
                        className={`tab-item ${activeTab === 'columns' ? 'active' : ''}`}
                        onClick={() => setActiveTab('columns')}
                    >
                        Значения столбцов
                    </button>
                    <button
                        className={`tab-item ${activeTab === 'agents' ? 'active' : ''}`}
                        onClick={() => setActiveTab('agents')}
                    >
                        Агенты
                    </button>
                </div>

                <div className="tab-content">
                    {activeTab === 'columns' && (
                        <div className="filter-section">
                            <h3>Фильтр по странам</h3>
                            <div className="filter-group">
                                {filterOptions.countries.map((country) => (
                                    <div key={country} className="filter-item">
                                        <input
                                            type="checkbox"
                                            checked={filters.country?.includes(country) || false}
                                            onChange={() => handleColumnFilterChange('country', country)}
                                        />
                                        <label>{country}</label>
                                    </div>
                                ))}
                            </div>

                            <h3>Фильтр по статусу</h3>
                            <div className="filter-group">
                                {filterOptions.statuses.map((status) => (
                                    <div key={status} className="filter-item">
                                        <input
                                            type="checkbox"
                                            checked={filters.status?.includes(status) || false}
                                            onChange={() => handleColumnFilterChange('status', status)}
                                        />
                                        <label>{status}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {activeTab === 'agents' && (
                        <div className="filter-section">
                            <h3>Фильтр по агентам</h3>
                            <select
                                value={selectedAgent}
                                onChange={(e) => setSelectedAgent(e.target.value)}
                            >
                                <option value="">Выберите агента</option>
                                {filterOptions.agents.map((agent) => (
                                    <option key={agent.id} value={agent.id}>
                                        {agent.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>

                <div className="filter-modal-footer">
                    <button onClick={handleApplyFilters} className="apply-btn">Применить</button>
                    <button onClick={onClose} className="cancel-btn">Отмена</button>
                </div>
            </div>
        </div>
    );
};

export default FilterModal;
