// src/components/AdvancedSettingsModal.js
import axios from '../api/axios';
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  Alert,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const AdvancedSettingsModal = ({ open, handleClose, affiliate, handleUpdate }) => {
  const [formData, setFormData] = useState({
    team_id: '',
    daily_lead_limit: 100,
    is_active: true,
  });

  const [teams, setTeams] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (affiliate) {
      setFormData({
        team_id: affiliate.team_id || '',
        daily_lead_limit: affiliate.daily_lead_limit || 100,
        is_active: affiliate.is_active !== undefined ? affiliate.is_active : true,
      });
    }
  }, [affiliate]);

  useEffect(() => {
    // Fetch teams from API
    const fetchTeams = async () => {
      try {
        const response = await axios.get('/business-units/teams', {
          headers: { Authorization: `Bearer ${affiliate.token}` }, // Предполагается, что токен доступен
        });
        setTeams(response.data.teams);
      } catch (err) {
        console.error('Ошибка загрузки команд:', err);
      }
    };

    if (open) {
      fetchTeams();
    }
  }, [open, affiliate.token]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = () => {
    // Валидация
    if (!formData.team_id) {
      setError('Пожалуйста, выберите команду.');
      return;
    }

    handleUpdate(affiliate.id, formData);
    setError(null);
  };

  if (!affiliate) return null;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="advanced-settings-title"
    >
      <Box sx={style}>
        <Typography id="advanced-settings-title" variant="h6" component="h2" gutterBottom>
          Расширенные Настройки Аффилиата
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <Stack spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="team-select-label">Команда</InputLabel>
            <Select
              labelId="team-select-label"
              name="team_id"
              value={formData.team_id}
              label="Команда"
              onChange={handleChange}
            >
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Лимит лидов в день"
            name="daily_lead_limit"
            type="number"
            value={formData.daily_lead_limit}
            onChange={handleChange}
            fullWidth
          />
          <FormControlLabel
            control={
              <Switch
                checked={formData.is_active}
                onChange={handleChange}
                name="is_active"
                color="primary"
              />
            }
            label="Активен"
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Сохранить
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AdvancedSettingsModal;
