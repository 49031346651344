// src/components/AffiliateCard.js

import React from 'react';
import { Card, CardContent, Typography, IconButton, CardActions, Tooltip } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Settings as SettingsIcon } from '@mui/icons-material';

const AffiliateCard = ({ affiliate, onEdit, onDelete, onView, onAdvancedSettings }) => {
  return (
    <Card sx={{ maxWidth: 345, margin: '1rem', cursor: 'pointer' }}>
      <CardContent onClick={() => onView(affiliate)}>
        <Typography variant="h6" component="div">
          {affiliate.first_name} {affiliate.last_name}
        </Typography>
        <Typography color="text.secondary">
          {affiliate.email}
        </Typography>
        <Typography color="text.secondary">
          {affiliate.phone}
        </Typography>
        <Typography color="text.secondary">
          {affiliate.country}
        </Typography>
      </CardContent>
      <CardActions>
        <Tooltip title="Редактировать">
          <IconButton color="primary" onClick={() => onEdit(affiliate)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Расширенные настройки">
          <IconButton color="secondary" onClick={() => onAdvancedSettings(affiliate)}>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Удалить">
          <IconButton color="error" onClick={() => onDelete(affiliate.id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default AffiliateCard;
