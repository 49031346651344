// src/components/AddAffiliateModal.js

import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  Alert,
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const AddAffiliateModal = ({ open, handleClose, handleAdd }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    middle_name: '',
    phone: '',
    email: '',
    country: '',
    password: '',
    office_id: '',
    daily_lead_limit: '',
    whitelisted_ip: '',
  });

  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    // Простая валидация
    const { first_name, last_name, phone, email, country, password, office_id } = formData;
    if (!first_name || !last_name || !phone || !email || !country || !password || !office_id) {
      setError('Пожалуйста, заполните все обязательные поля');
      return;
    }

    // Преобразование whitelisted_ip из строки в массив
    const affiliateData = {
      ...formData,
      office_id: parseInt(formData.office_id, 10),
      daily_lead_limit: parseInt(formData.daily_lead_limit, 10) || 100,
      whitelisted_ip: formData.whitelisted_ip
        ? formData.whitelisted_ip.split(',').map(ip => ip.trim())
        : [],
    };

    handleAdd(affiliateData);
    setFormData({
      first_name: '',
      last_name: '',
      middle_name: '',
      phone: '',
      email: '',
      country: '',
      password: '',
      office_id: '',
      daily_lead_limit: '',
      whitelisted_ip: '',
    });
    setError(null);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-affiliate-title"
    >
      <Box sx={style}>
        <Typography id="add-affiliate-title" variant="h6" component="h2" gutterBottom>
          Добавить аффилиата
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <Stack spacing={2}>
          <TextField
            label="Имя"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Фамилия"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Отчество"
            name="middle_name"
            value={formData.middle_name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Телефон"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Страна"
            name="country"
            value={formData.country}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Пароль"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="ID офиса"
            name="office_id"
            type="number"
            value={formData.office_id}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Лимит лидов в день"
            name="daily_lead_limit"
            type="number"
            value={formData.daily_lead_limit}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Белый список IP (через запятую)"
            name="whitelisted_ip"
            value={formData.whitelisted_ip}
            onChange={handleChange}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Добавить
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddAffiliateModal;
