// src/pages/BusinessUnits/CallCenterDetails.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import axios from '../../api/axios';
import { useAuth } from '../../context/AuthContext';

const CallCenterDetails = () => {
  const { callCenterId } = useParams();
  const { token } = useAuth();
  const [callCenter, setCallCenter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchCallCenter = async () => {
      try {
        const response = await axios.get(`/business-units/call-centers/${callCenterId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCallCenter(response.data.callCenter);
      } catch (err) {
        console.error('Ошибка загрузки колл-центра:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchCallCenter();
  }, [callCenterId, token]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error || !callCenter) {
    return (
      <Box>
        <Alert severity="error">Ошибка загрузки колл-центра. Попробуйте позже.</Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        {callCenter.name}
      </Typography>
      <Typography variant="subtitle1">
        Местоположение: {callCenter.location}
      </Typography>
      {/* Добавьте дополнительную информацию по необходимости */}
    </Box>
  );
};

export default CallCenterDetails;
