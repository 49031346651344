// src/App.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuth } from './context/AuthContext';
import { useThemeContext } from './context/ThemeContext';
import { useLanguage } from './context/LanguageContext';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Leads from './pages/Leads/Leads';
import LeadDetails from './pages/Leads/LeadDetails';
import Users from './pages/Users/Users';
import CreateUser from './pages/Users/CreateUser';
import UserDetail from './pages/Users/UserDetail'; // Импортируем компонент UserDetail
import Settings from './pages/Settings/Settings';
import Chats from './pages/Chats/Chats';
import Support from './pages/Support/Support';
import Agents from './pages/Accounts/Agents';
import TeamLeads from './pages/Accounts/TeamLeads';
import Supports from './pages/Accounts/Supports';
import Heads from './pages/Accounts/Heads';
import Retainers from './pages/Accounts/Retainers';
import Affiliates from './pages/BusinessUnits/Affiliates';
import CallCenters from './pages/BusinessUnits/CallCenters';
import CallCenterDetails from './pages/BusinessUnits/CallCenterDetails';
import Teams from './pages/BusinessUnits/Teams';
import TeamDetails from './pages/BusinessUnits/TeamDetails';
import Layout from './components/Layout';
import ThemeSwitcher from './components/ThemeSwitcher';
import LanguageSwitcher from './components/LanguageSwitcher';

// Импортируем компонент страницы AiChat
import AiChatPage from './pages/AiChat/AiChat';

const App = () => {
  const { isAuthenticated, loading } = useAuth();
  const { toggleTheme, isDarkMode } = useThemeContext();
  const { changeLanguage } = useLanguage();

  if (loading) return <div>Loading...</div>;

  return (
    <div>
      {/* Переключатели для тем и языков */}
      <header style={{ display: 'flex', justifyContent: 'space-between', padding: '1rem' }}>
        <ThemeSwitcher toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
        <LanguageSwitcher changeLanguage={changeLanguage} />
      </header>

      <Routes>
        {/* Роут на страницу логина */}
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />}
        />

        {/* Защищённые роуты */}
        <Route element={<ProtectedRoute />}>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/leads" element={<Leads />} />
            <Route path="/leads/:leadId" element={<LeadDetails />} />
            <Route path="/users" element={<Users />} />
            <Route path="/users/create" element={<CreateUser />} />
            <Route path="/users/:userId" element={<UserDetail />} /> {/* Добавленный маршрут */}
            <Route path="/settings" element={<Settings />} />
            <Route path="/chats" element={<Chats />} />
            <Route path="/support" element={<Support />} />

            {/* Маршруты для раздела "Аккаунты" */}
            <Route path="/accounts/agents" element={<Agents />} />
            <Route path="/accounts/team-leads" element={<TeamLeads />} />
            <Route path="/accounts/supports" element={<Supports />} />
            <Route path="/accounts/heads" element={<Heads />} />
            <Route path="/accounts/retainers" element={<Retainers />} />

            {/* Маршруты для раздела "Бизнес-подразделения" */}
            <Route path="/business-units/affiliates" element={<Affiliates />} />
            <Route path="/business-units/call-centers" element={<CallCenters />} />
            <Route
              path="/business-units/call-centers/:callCenterId"
              element={<CallCenterDetails />}
            />
            <Route path="/business-units/teams" element={<Teams />} />
            <Route path="/business-units/teams/:teamId" element={<TeamDetails />} />

            {/* Добавленный маршрут для страницы AI Ассистента */}
            <Route path="/ai-chat" element={<AiChatPage />} />
          </Route>
        </Route>

        {/* Редирект на логин, если неавторизован или неизвестный путь */}
        <Route
          path="*"
          element={<Navigate to={isAuthenticated ? '/dashboard' : '/login'} replace />}
        />
      </Routes>
    </div>
  );
};

export default App;
