// src/pages/Leads/Leads.js

import React, { useEffect, useState, useRef, useMemo } from 'react';
import axiosInstance from '../../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import AddLeadModal from './AddLeadModal';
import FilterModal from './FilterModal';
import ImportLeadsModal from './ImportLeadsModal';
import './Leads.css';
import { useAuth } from '../../context/AuthContext';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Leads = () => {
  const [leads, setLeads] = useState([]);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    id: true,
    surname: true,
    first_name: true,
    patronymic: true,
    phone_number: true,
    email: true,
    country: true,
    date_of_birth: true,
    status: true,
    comments: true,
    affiliate_comment: true,
    created_at: true,
    updated_at: true,
  });

  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Новое состояние для поиска
  const [searchTerm, setSearchTerm] = useState('');

  // Функция для загрузки данных лидов
  const fetchLeads = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/leads');
      setLeads(response.data || []); // Если данных нет, используем пустой массив
      console.log('Загруженные лиды:', response.data); // Для отладки
    } catch (error) {
      console.error('Ошибка при загрузке лидов:', error);
      if (error.response && error.response.status === 401) {
        auth.logout();
        navigate('/login');
      } else {
        setError('Ошибка при загрузке лидов. Попробуйте позже.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, navigate]);

  // Функция для добавления нового лида
  const handleAddLead = async (newLead) => {
    try {
      await axiosInstance.post('/leads', newLead);
      setAddModalOpen(false);
      fetchLeads();
    } catch (error) {
      console.error('Ошибка при добавлении лида:', error);
      setError('Ошибка при добавлении лида. Попробуйте позже.');
    }
  };

  const handleColumnVisibilityChange = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const handleLeadClick = (leadId) => {
    if (leadId) {
      navigate(`/leads/${leadId}`);
    }
  };

  const handleOpenImportModal = () => {
    setImportModalOpen(true);
  };

  // Фильтрация лидов на основе searchTerm
  const filteredLeads = useMemo(() => {
    if (!searchTerm) return leads;

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return leads.filter((lead) => {
      return (
        (lead.id && lead.id.toString().includes(lowerCaseSearchTerm)) ||
        (lead.first_name && lead.first_name.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (lead.surname && lead.surname.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (lead.email && lead.email.toLowerCase().includes(lowerCaseSearchTerm))
      );
    });
  }, [leads, searchTerm]);

  // Отображение состояния загрузки
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  // Отображение ошибки
  if (error) {
    return (
      <Box>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <div className="leads-page">
      <Typography variant="h4" gutterBottom>
        Лиды
      </Typography>

      {/* Поле поиска */}
      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Поиск по ID, имени, фамилии или Email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Панель инструментов */}
      <div className="toolbar">
        <Button variant="contained" onClick={() => setAddModalOpen(true)} className="btn-add">
          Добавить Лид
        </Button>
        <Button variant="outlined" onClick={() => setFilterModalOpen(true)} className="btn-filter">
          Фильтры
        </Button>
        <Button variant="outlined" onClick={handleOpenImportModal} className="btn-import">
          Импорт из Excel
        </Button>
      </div>

      {/* Таблица лидов */}
      <Box className="leads-table-container">
        <table className="leads-table">
          <thead>
            <tr>
              {visibleColumns.id && <th>ID</th>}
              {visibleColumns.surname && <th>Фамилия</th>}
              {visibleColumns.first_name && <th>Имя</th>}
              {visibleColumns.patronymic && <th>Отчество</th>}
              {visibleColumns.phone_number && <th>Телефон</th>}
              {visibleColumns.email && <th>Email</th>}
              {visibleColumns.country && <th>Страна</th>}
              {visibleColumns.status && <th>Статус</th>}
              {visibleColumns.comments && <th>Комментарии</th>}
              {visibleColumns.affiliate_comment && <th>Комментарий аффилиата</th>}
              {visibleColumns.created_at && <th>Дата создания</th>}
              {visibleColumns.updated_at && <th>Последнее обновление</th>}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredLeads) && filteredLeads.length > 0 ? (
              filteredLeads.map((lead) => (
                <tr key={lead.id}>
                  {visibleColumns.id && (
                    <td
                      className="clickable-id"
                      onClick={() => handleLeadClick(lead.id)}
                      style={{ cursor: 'pointer', color: '#1976d2' }}
                    >
                      {lead.id}
                    </td>
                  )}
                  {visibleColumns.surname && <td>{lead.surname}</td>}
                  {visibleColumns.first_name && <td>{lead.first_name}</td>}
                  {visibleColumns.patronymic && <td>{lead.patronymic || 'N/A'}</td>}
                  {visibleColumns.phone_number && <td>{lead.phone_number}</td>}
                  {visibleColumns.email && <td>{lead.email}</td>}
                  {visibleColumns.country && <td>{lead.country}</td>}
                  {visibleColumns.status && <td>{lead.status ? lead.status.name : 'Не задан'}</td>}
                  {visibleColumns.comments && (
                    <td>{lead.comments.length > 0 ? lead.comments[0].text : 'Нет комментариев'}</td>
                  )}
                  {visibleColumns.affiliate_comment && (
                    <td>{lead.affiliate ? lead.affiliate.comment : 'N/A'}</td>
                  )}
                  {visibleColumns.created_at && <td>{new Date(lead.created_at).toLocaleDateString()}</td>}
                  {visibleColumns.updated_at && <td>{new Date(lead.updated_at).toLocaleDateString()}</td>}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={Object.values(visibleColumns).filter(Boolean).length} style={{ textAlign: 'center' }}>
                  Нет результатов для отображения.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>

      {/* Модальные окна */}
      {isAddModalOpen && (
        <AddLeadModal onClose={() => setAddModalOpen(false)} onAddLead={handleAddLead} />
      )}
      {isFilterModalOpen && (
        <FilterModal
          onClose={() => setFilterModalOpen(false)}
          columns={Object.keys(visibleColumns)}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
        />
      )}
      {isImportModalOpen && (
        <ImportLeadsModal onClose={() => setImportModalOpen(false)} onImportSuccess={fetchLeads} />
      )}
    </div>
  );
};

export default Leads;
