// src/pages/Settings/Settings.js
import React from 'react';

const Settings = () => {
  return (
    <div>
      <h2>Настройки</h2>
      {/* Добавьте содержимое страницы настроек */}
    </div>
  );
};

export default Settings;
