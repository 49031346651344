// src/context/LanguageContext.js
import React, { createContext, useContext, useState } from 'react';

// Создаем контекст языка
const LanguageContext = createContext();

// Провайдер для управления языковым контекстом
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('ru'); // Установите начальный язык

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage: changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Хук для использования контекста языка
export const useLanguage = () => useContext(LanguageContext);
