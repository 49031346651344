// src/context/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import axiosInstance from '../utils/axiosConfig'; // Убедитесь, что этот путь корректный
import { useNavigate } from 'react-router-dom';

// Создаём контекст аутентификации
export const AuthContext = createContext();

// Провайдер контекста аутентификации
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [auth, setAuth] = useState({
    token: localStorage.getItem('token'),
    user: null,
    roles: [],
    loading: true,
  });

  // Функция для получения пользователя и ролей
  const fetchUserAndRoles = async (token) => {
    try {
      console.log('Fetching user and roles with token:', token);

      // Установка заголовка Authorization
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;

      // Запрос на получение информации о пользователе
      const userResponse = await axiosInstance.get('/auth/me');
      const user = userResponse.data.user;
      console.log('User fetched:', user);

      // Запрос на получение ролей
      const rolesResponse = await axiosInstance.get('/roles');
      console.log('Roles response data:', rolesResponse.data);

      let roles = [];

      // Обработка ответа от эндпоинта /roles
      if (rolesResponse.data.roles && Array.isArray(rolesResponse.data.roles)) {
        // Если ответ содержит свойство roles
        roles = rolesResponse.data.roles.map(role => role.name);
      } else if (Array.isArray(rolesResponse.data)) {
        // Если ответ сам по себе массив
        roles = rolesResponse.data.map(role => role.name);
      } else {
        // Неожиданный формат ответа
        console.error('Unexpected roles response format:', rolesResponse.data);
        roles = [];
      }

      console.log('Roles fetched:', roles);

      setAuth({
        token,
        user,
        roles,
        loading: false,
      });
    } catch (error) {
      console.error('Error fetching user or roles:', error);
      logout(); // Выход из системы при ошибке
    }
  };

  // useEffect для инициализации аутентификации при загрузке приложения
  useEffect(() => {
    const initializeAuth = async () => {
      if (auth.token) {
        await fetchUserAndRoles(auth.token);
      } else {
        setAuth(prev => ({ ...prev, loading: false }));
      }
    };

    initializeAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token]);

  // Функция для входа пользователя
  const login = async (email, password) => {
    try {
      console.log('Attempting to login with:', email);
      
      // Запрос на вход
      const response = await axiosInstance.post('/auth/login', { email, password });
      const { token } = response.data;

      console.log('Login successful, token:', token);

      // Сохранение токена в localStorage
      localStorage.setItem('token', token);
      setAuth(prev => ({ ...prev, token }));

      // Получение пользователя и ролей
      await fetchUserAndRoles(token);

      // Навигация на дашборд после успешного входа и получения данных
      navigate('/dashboard');

      return { success: true };
    } catch (error) {
      console.error('Login error:', error);
      const message = error.response?.data?.message || 'Ошибка при входе';
      return { success: false, message };
    }
  };

  // Функция для выхода пользователя
  const logout = () => {
    console.log('Logging out...');
    
    // Удаление токена из localStorage
    localStorage.removeItem('token');

    // Удаление заголовка Authorization из axiosInstance
    delete axiosInstance.defaults.headers.common['Authorization'];

    // Обновление состояния аутентификации
    setAuth({
      token: null,
      user: null,
      roles: [],
      loading: false,
    });

    // Навигация на страницу логина
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {!auth.loading && children}
    </AuthContext.Provider>
  );
};

// Хук для использования контекста аутентификации
export const useAuth = () => useContext(AuthContext);
