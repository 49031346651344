// src/pages/BusinessUnits/components/AddTeamModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Autocomplete,
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import axios from '../api/axios';

const AddTeamModal = ({ open, handleClose, handleAddTeam }) => {
  const { token } = useAuth();
  const [newTeam, setNewTeam] = useState({
    name: '',
    leaderId: null,
    description: '',
  });
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (open) {
      const fetchUsers = async () => {
        try {
          const response = await axios.get('/users', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUsers(response.data.users);
        } catch (error) {
          console.error('Ошибка загрузки пользователей:', error);
        }
      };
      fetchUsers();
    }
  }, [open, token]);

  const handleChange = (e) => {
    setNewTeam((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.name]: '' }));
  };

  const handleLeaderChange = (event, value) => {
    setNewTeam((prev) => ({ ...prev, leaderId: value ? value.id : null }));
    setErrors((prev) => ({ ...prev, leaderId: '' }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!newTeam.name) tempErrors.name = 'Введите название команды';
    if (!newTeam.leaderId) tempErrors.leaderId = 'Выберите лидера команды';
    if (!newTeam.description)
      tempErrors.description = 'Введите описание команды';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      handleAddTeam(newTeam);
      setNewTeam({ name: '', leaderId: null, description: '' });
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Добавить новую команду</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              label="Название команды"
              name="name"
              value={newTeam.name}
              onChange={handleChange}
              fullWidth
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={users}
              getOptionLabel={(option) => option.username}
              onChange={handleLeaderChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Лидер команды"
                  error={!!errors.leaderId}
                  helperText={errors.leaderId}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Описание"
              name="description"
              value={newTeam.description}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Отмена
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Добавить команду
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTeamModal;
