// src/components/CallCenterModal.js

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const CallCenterModal = ({ open, handleClose, callCenter }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Информация о колл-центре</DialogTitle>
      <DialogContent>
        <Typography variant="h6">{callCenter.name}</Typography>
        <Typography variant="subtitle1">Местоположение: {callCenter.location}</Typography>
        {/* Добавьте дополнительную информацию, если необходимо */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Закрыть</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CallCenterModal;
