// src/components/EditCallCenterModal.js

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';

const EditCallCenterModal = ({ open, handleClose, callCenter, handleUpdateCallCenter }) => {
  const [name, setName] = useState(callCenter.name || '');
  const [location, setLocation] = useState(callCenter.location || '');

  const handleSubmit = () => {
    handleUpdateCallCenter({ id: callCenter.id, name, location });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Редактировать колл-центр</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Название"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Местоположение"
          fullWidth
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отмена</Button>
        <Button onClick={handleSubmit} variant="contained">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCallCenterModal;
