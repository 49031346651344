// src/pages/Accounts/Heads.js

import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress, Alert, List, ListItem, ListItemText } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig'; // Используем axiosInstance с настроенными перехватчиками

const Heads = () => {
  const { auth } = useAuth(); // Получаем auth объект из контекста
  const navigate = useNavigate();
  const [heads, setHeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Функция для загрузки данных хедов
    const fetchHeads = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get('/accounts/heads'); // Токен автоматически добавляется в заголовок
        setHeads(response.data.heads);
      } catch (err) {
        console.error('Ошибка загрузки хедов:', err);
        if (err.response && err.response.status === 401) {
          // Если токен истёк или невалиден, вызываем logout и перенаправляем на логин
          auth.logout();
          navigate('/login');
        } else {
          setError('Ошибка загрузки хедов. Попробуйте позже.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchHeads();
  }, [auth, navigate]);

  // Отображение состояния загрузки
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  // Отображение ошибки
  if (error) {
    return (
      <Box>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Хеды
      </Typography>
      {heads.length > 0 ? (
        <List>
          {heads.map((head) => (
            <ListItem key={head.id}>
              <ListItemText primary={head.name} secondary={head.email} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1">Нет хедов для отображения.</Typography>
      )}
    </Box>
  );
};

export default Heads;
