// src/components/AffiliateDetailsModal.js

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
  Stack,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Alert,
} from '@mui/material';
import axios from '../api/axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: '80vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const AffiliateDetailsModal = ({ open, handleClose, affiliate }) => {
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    if (affiliate) {
      // Предположим, что доступные колонки можно получить с API
      const fetchAvailableColumns = async () => {
        try {
          const response = await axios.get('/business-units/leads/columns', {
            headers: { Authorization: `Bearer ${affiliate.token}` }, // Предполагается, что токен доступен
          });
          setAvailableColumns(response.data.columns);
          setSelectedColumns(response.data.selected_columns);
        } catch (err) {
          console.error('Ошибка загрузки колонок:', err);
        }
      };

      fetchAvailableColumns();
    }
  }, [affiliate]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setSelectedColumns((prev) => [...prev, name]);
    } else {
      setSelectedColumns((prev) => prev.filter((col) => col !== name));
    }
  };

  const handleSaveColumns = async () => {
    try {
      const response = await axios.put(
        `/business-units/affiliates/${affiliate.id}/leads/columns`,
        { selected_columns: selectedColumns },
        {
          headers: { Authorization: `Bearer ${affiliate.token}` },
        }
      );
      setSuccess('Колонки успешно сохранены.');
      setError(null);
    } catch (err) {
      console.error('Ошибка сохранения колонок:', err);
      setError('Ошибка сохранения колонок. Попробуйте позже.');
      setSuccess(null);
    }
  };

  if (!affiliate) return null;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="affiliate-details-title"
    >
      <Box sx={style}>
        <Typography id="affiliate-details-title" variant="h6" component="h2" gutterBottom>
          Профиль аффилиата
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Имя" secondary={affiliate.first_name} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Фамилия" secondary={affiliate.last_name} />
          </ListItem>
          {affiliate.middle_name && (
            <ListItem>
              <ListItemText primary="Отчество" secondary={affiliate.middle_name} />
            </ListItem>
          )}
          <ListItem>
            <ListItemText primary="Телефон" secondary={affiliate.phone} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Email" secondary={affiliate.email} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Страна" secondary={affiliate.country} />
          </ListItem>
          <ListItem>
            <ListItemText primary="API ключ" secondary={affiliate.api_key} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Лимит лидов в день" secondary={affiliate.daily_lead_limit} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Белый список IP"
              secondary={
                affiliate.whitelisted_ip && affiliate.whitelisted_ip.length > 0 ? (
                  <Stack direction="row" spacing={1}>
                    {affiliate.whitelisted_ip.map((ip, index) => (
                      <Chip key={index} label={ip} size="small" />
                    ))}
                  </Stack>
                ) : (
                  'Не указан'
                )
              }
            />
          </ListItem>
        </List>

        {/* Раздел выбора колонок */}
        <Box mt={4}>
          <Typography variant="h6" gutterBottom>
            Настройки колонок для базы лидов
          </Typography>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}
          <FormControl component="fieldset">
            <FormGroup>
              {availableColumns.map((column) => (
                <FormControlLabel
                  key={column}
                  control={
                    <Checkbox
                      checked={selectedColumns.includes(column)}
                      onChange={handleCheckboxChange}
                      name={column}
                    />
                  }
                  label={column}
                />
              ))}
            </FormGroup>
          </FormControl>
          <Button variant="contained" color="primary" onClick={handleSaveColumns} sx={{ mt: 2 }}>
            Сохранить Колонки
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AffiliateDetailsModal;
