// src/pages/Users/Users.js

import React, { useEffect, useState, useMemo } from 'react';
import axiosInstance from '../../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import AddUserModal from '../../components/AddUserModal';
import FilterModal from './FilterModal';
import ImportUsersModal from './ImportUsersModal';
import './Users.css';
import { useAuth } from '../../context/AuthContext';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    id: true,
    login: true,
    email: true,
    roles: true,
    office: true,
    created_at: true,
    updated_at: true,
  });

  const navigate = useNavigate();
  const { auth } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Новое состояние для поиска
  const [searchTerm, setSearchTerm] = useState('');

  // Функция для загрузки данных пользователей
  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('/users');
      setUsers(response.data || []); // Если данных нет, используем пустой массив
      console.log('Загруженные пользователи:', response.data); // Для отладки
    } catch (error) {
      console.error('Ошибка при загрузке пользователей:', error);
      if (error.response && error.response.status === 401) {
        auth.logout();
        navigate('/login');
      } else {
        setError('Ошибка при загрузке пользователей. Попробуйте позже.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, navigate]);

  // Функция для добавления нового пользователя
  const handleAddUser = async (newUserData) => {
    try {
      console.log('Adding user with data:', newUserData);
      const response = await axiosInstance.post('/users', newUserData);
      // Предполагается, что сервер возвращает созданного пользователя в response.data.user
      setUsers((prevUsers) => [...prevUsers, response.data.user]);
      setAddModalOpen(false);
      setError(null);
      console.log('Пользователь успешно добавлен:', response.data.user);
    } catch (err) {
      console.error('Ошибка при добавлении пользователя:', err);
      if (err.response && err.response.data && err.response.data.message) {
        setError(`Не удалось добавить пользователя: ${err.response.data.message}`);
      } else if (err.response && err.response.data) {
        setError(`Не удалось добавить пользователя: ${JSON.stringify(err.response.data)}`);
      } else {
        setError('Не удалось добавить пользователя. Проверьте введённые данные.');
      }
    }
  };

  const handleColumnVisibilityChange = (column) => {
    setVisibleColumns((prev) => ({
      ...prev,
      [column]: !prev[column],
    }));
  };

  const handleUserClick = (userId) => {
    if (userId) {
      navigate(`/users/${userId}`);
    }
  };

  const handleOpenImportModal = () => {
    setImportModalOpen(true);
  };

  // Фильтрация пользователей на основе searchTerm
  const filteredUsers = useMemo(() => {
    if (!searchTerm) return users;

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return users.filter((user) => {
      return (
        (user.id && user.id.toString().includes(lowerCaseSearchTerm)) ||
        (user.login && user.login.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (user.email && user.email.toLowerCase().includes(lowerCaseSearchTerm))
      );
    });
  }, [users, searchTerm]);

  // Отображение состояния загрузки
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className="users-page">
      <Typography variant="h4" gutterBottom>
        Пользователи
      </Typography>

      {/* Поле поиска */}
      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Поиск по ID, логину или Email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Панель инструментов */}
      <div className="toolbar">
        <Button variant="contained" onClick={() => setAddModalOpen(true)} className="btn-add">
          Добавить Пользователя
        </Button>
        <Button variant="outlined" onClick={() => setFilterModalOpen(true)} className="btn-filter">
          Фильтры
        </Button>
        <Button variant="outlined" onClick={handleOpenImportModal} className="btn-import">
          Импорт из Excel
        </Button>
      </div>

      {/* Таблица пользователей */}
      <Box className="users-table-container">
        <table className="users-table">
          <thead>
            <tr>
              {visibleColumns.id && <th>ID</th>}
              {visibleColumns.login && <th>Логин</th>}
              {visibleColumns.email && <th>Email</th>}
              {visibleColumns.roles && <th>Роли</th>}
              {visibleColumns.office && <th>Офис</th>}
              {visibleColumns.created_at && <th>Дата создания</th>}
              {visibleColumns.updated_at && <th>Последнее обновление</th>}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(filteredUsers) && filteredUsers.length > 0 ? (
              filteredUsers.map((user) => (
                <tr key={user.id}>
                  {visibleColumns.id && (
                    <td
                      className="clickable-id"
                      onClick={() => handleUserClick(user.id)}
                      style={{ cursor: 'pointer', color: '#1976d2' }}
                    >
                      {user.id}
                    </td>
                  )}
                  {visibleColumns.login && <td>{user.login}</td>}
                  {visibleColumns.email && <td>{user.email}</td>}
                  {visibleColumns.roles && (
                    <td>{Array.isArray(user.roles) ? user.roles.join(', ') : 'Нет ролей'}</td>
                  )}
                  {visibleColumns.office && <td>{user.office ? user.office.name : 'Не назначен'}</td>}
                  {visibleColumns.created_at && <td>{new Date(user.created_at).toLocaleDateString()}</td>}
                  {visibleColumns.updated_at && <td>{new Date(user.updated_at).toLocaleDateString()}</td>}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={Object.values(visibleColumns).filter(Boolean).length} style={{ textAlign: 'center' }}>
                  Нет результатов для отображения.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>

      {/* Отображение ошибки при добавлении пользователя */}
      {error && (
        <Box mt={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}

      {/* Модальные окна */}
      {isAddModalOpen && (
        <AddUserModal
          open={isAddModalOpen}
          handleClose={() => setAddModalOpen(false)}
          handleAdd={handleAddUser} // Передаём функцию handleAddUser
        />
      )}
      {isFilterModalOpen && (
        <FilterModal
          open={isFilterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          columns={Object.keys(visibleColumns)}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
        />
      )}
      {isImportModalOpen && (
        <ImportUsersModal onClose={() => setImportModalOpen(false)} onImportSuccess={fetchUsers} />
      )}
    </div>
  );

};

export default Users;
