// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { CircularProgress, Box } from '@mui/material';

const ProtectedRoute = ({ roles = [] }) => {
  const { auth } = useAuth();

  if (auth.loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!auth.token) {
    return <Navigate to="/login" replace />;
  }

  // Проверка роли пользователя
  if (roles.length && !roles.includes(auth.user.role)) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <h2>Доступ запрещён</h2>
      </Box>
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
