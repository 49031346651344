// src/pages/BusinessUnits/Teams.js

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import AddTeamModal from '../../components/AddTeamModal';
import EditTeamModal from '../../components/EditTeamModal';

const Teams = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]); // Для получения имен лидеров
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Состояния для модальных окон
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    const fetchTeamsAndUsers = async () => {
      try {
        const [teamsResponse, usersResponse] = await Promise.all([
          axios.get('/business-units/teams', {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get('/users', {
            headers: { Authorization: `Bearer ${token}` },
          }),
        ]);

        console.log('teamsResponse.data:', teamsResponse.data);
        console.log('usersResponse.data:', usersResponse.data);

        setTeams(teamsResponse.data.teams || []);
        setUsers(usersResponse.data.users || []);
      } catch (err) {
        console.error('Ошибка загрузки команд или пользователей:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamsAndUsers();
  }, [token, navigate]);

  // Обработчики для модальных окон
  const handleOpenAddModal = () => setIsAddModalOpen(true);
  const handleCloseAddModal = () => setIsAddModalOpen(false);

  const handleOpenEditModal = (team) => {
    setSelectedTeam(team);
    setIsEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setSelectedTeam(null);
    setIsEditModalOpen(false);
  };

  // Обработчики для добавления, обновления и удаления команд
  const handleAddTeam = async (newTeam) => {
    try {
      const response = await axios.post('/business-units/teams', newTeam, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('Добавлена команда:', response.data);

      // Добавляем проверку и устанавливаем команду
      setTeams((prevTeams) => [...prevTeams, response.data.team || response.data]);
    } catch (error) {
      console.error('Ошибка добавления команды:', error);
    }
  };

  const handleUpdateTeam = async (updatedTeam) => {
    try {
      const response = await axios.put(
        `/business-units/teams/${updatedTeam.id}`,
        updatedTeam,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log('Обновлена команда:', response.data);

      setTeams((prevTeams) =>
        prevTeams.map((team) =>
          team.id === updatedTeam.id ? response.data.team || response.data : team
        )
      );
    } catch (error) {
      console.error('Ошибка обновления команды:', error);
    }
  };

  const handleDeleteTeam = async (teamId) => {
    try {
      await axios.delete(`/business-units/teams/${teamId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTeams((prevTeams) => prevTeams.filter((team) => team.id !== teamId));
    } catch (error) {
      console.error('Ошибка удаления команды:', error);
    }
  };

  // Переход к деталям команды
  const handleTeamClick = (teamId) => {
    navigate(`/business-units/teams/${teamId}`);
  };

  // Получение имени лидера по его ID
  const getLeaderName = (leaderId) => {
    const leader = users.find((user) => user.id === leaderId);
    return leader ? leader.username : 'Не назначен';
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Alert severity="error">
          Ошибка загрузки команд. Попробуйте позже.
        </Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Команды
      </Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleOpenAddModal}
        sx={{ mb: 2 }}
      >
        Добавить команду
      </Button>
      {Array.isArray(teams) && teams.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Название команды</TableCell>
                <TableCell>Лидер команды</TableCell>
                <TableCell>Описание</TableCell>
                <TableCell align="right">Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams.map((team) =>
                team ? (
                  <TableRow
                    key={team.id}
                    hover
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleTeamClick(team.id)}
                  >
                    <TableCell>{team.name || 'Без названия'}</TableCell>
                    <TableCell>{getLeaderName(team.leaderId)}</TableCell>
                    <TableCell>{team.description || 'Нет описания'}</TableCell>
                    <TableCell align="right">
                      <Tooltip title="Редактировать">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenEditModal(team);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Удалить">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTeam(team.id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ) : null
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">Нет команд для отображения.</Typography>
      )}

      {/* Модальные окна */}
      <AddTeamModal
        open={isAddModalOpen}
        handleClose={handleCloseAddModal}
        handleAddTeam={handleAddTeam}
      />
      {selectedTeam && (
        <EditTeamModal
          open={isEditModalOpen}
          handleClose={handleCloseEditModal}
          team={selectedTeam}
          handleUpdateTeam={handleUpdateTeam}
        />
      )}
    </Box>
  );
};

export default Teams;
