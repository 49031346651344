// src/components/ProfileModal/ProfileModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Avatar } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import axios from '../../api/axios';

const ProfileModal = ({ open, handleClose }) => {
  const { token } = useAuth();
  const [profile, setProfile] = useState({ email: '', name: '', avatar: '' });
  const [avatarFile, setAvatarFile] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('/profile', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setProfile(response.data);
      } catch (error) {
        console.error('Ошибка загрузки профиля:', error);
      }
    };

    if (open) fetchProfile();
  }, [open, token]);

  const handleAvatarChange = (event) => {
    setAvatarFile(event.target.files[0]);
  };

  const handleAvatarUpload = async () => {
    if (!avatarFile) return;

    const formData = new FormData();
    formData.append('avatar', avatarFile);

    try {
      const response = await axios.post('/profile/avatar', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      setProfile((prev) => ({ ...prev, avatar: response.data.avatar }));
    } catch (error) {
      console.error('Ошибка загрузки аватарки:', error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ width: 300, p: 4, m: 'auto', mt: 10, bgcolor: 'background.paper', borderRadius: 1 }}>
        <Typography variant="h6" gutterBottom>
          Мой профиль
        </Typography>
        <Avatar src={profile.avatar} sx={{ width: 80, height: 80, mb: 2 }} />
        <TextField label="Email" value={profile.email} fullWidth margin="normal" disabled />
        <TextField label="Имя" value={profile.name} fullWidth margin="normal" disabled />
        
        <input type="file" onChange={handleAvatarChange} style={{ margin: '10px 0' }} />
        <Button variant="contained" onClick={handleAvatarUpload} fullWidth sx={{ mt: 2 }}>
          Загрузить аватар
        </Button>
      </Box>
    </Modal>
  );
};

export default ProfileModal;
