// src/pages/Users/UserDetail.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import { useAuth } from '../../context/AuthContext';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Alert,
  Button,
  Avatar,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const UserDetail = () => {
  const { id } = useParams(); // Получаем ID пользователя из URL
  const navigate = useNavigate();
  const { auth } = useAuth();
  
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Функция для загрузки данных пользователя
  const fetchUser = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/users/${id}`); // Предполагается, что есть эндпоинт /users/:id
      setUser(response.data.user); // Предполагается, что сервер возвращает объект пользователя в поле user
      setError(null);
    } catch (err) {
      console.error('Ошибка при загрузке пользователя:', err);
      if (err.response && err.response.status === 401) {
        auth.logout();
        navigate('/login');
      } else if (err.response && err.response.status === 404) {
        setError('Пользователь не найден.');
      } else {
        setError('Ошибка при загрузке данных пользователя. Попробуйте позже.');
      }
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }
  
  if (error) {
    return (
      <Box p={2}>
        <Alert severity="error">{error}</Alert>
        <Box mt={2}>
          <Button variant="contained" onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
            Назад
          </Button>
        </Box>
      </Box>
    );
  }
  
  return (
    <Box p={4}>
      <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>
        Назад
      </Button>
      <Card sx={{ maxWidth: 600, margin: '20px auto' }}>
        <CardContent>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Avatar
              sx={{ width: 100, height: 100, mb: 2 }}
              src={user.avatarUrl || '/default-avatar.png'} // Предполагается, что у пользователя есть URL аватара
              alt={`${user.first_name} ${user.surname}`}
            />
            <Typography variant="h5" gutterBottom>
              {user.first_name} {user.surname}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              {user.email}
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography variant="body1"><strong>ID:</strong> {user.id}</Typography>
            <Typography variant="body1"><strong>Логин:</strong> {user.login}</Typography>
            <Typography variant="body1"><strong>Роль:</strong> {user.roles.join(', ')}</Typography>
            <Typography variant="body1"><strong>Офис:</strong> {user.office ? user.office.name : 'Не назначен'}</Typography>
            <Typography variant="body1"><strong>Дата создания:</strong> {new Date(user.created_at).toLocaleDateString()}</Typography>
            <Typography variant="body1"><strong>Последнее обновление:</strong> {new Date(user.updated_at).toLocaleDateString()}</Typography>
            {/* Добавьте дополнительные поля по необходимости */}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserDetail;
