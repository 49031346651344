// src/pages/Support/Support.js
import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { Typography } from '@mui/material';

const Support = () => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Typography variant="h6">Вы не авторизованы.</Typography>;
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Поддержка
      </Typography>
      <Typography>
        Здесь вы можете обратиться за поддержкой.
      </Typography>
    </div>
  );
};

export default Support;
