// src/pages/Users/CreateUser.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormHelperText,
  CircularProgress,
  Alert,
  Box,
  Typography,
} from '@mui/material';
import axiosInstance from '../../utils/axiosConfig';
import { useAuth } from '../../context/AuthContext';

const CreateUser = ({ open, onClose, onUserCreated }) => {
  const { auth } = useAuth();
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [errorRoles, setErrorRoles] = useState(null);

  const [teams, setTeams] = useState([]);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [errorTeams, setErrorTeams] = useState(null);

  const [formData, setFormData] = useState({
    login: '',
    email: '',
    password: '',
    roles: [],
    team: '',
  });

  const [submitting, setSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [successSubmit, setSuccessSubmit] = useState(false);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.get('/roles'); // Предполагается, что есть эндпоинт /roles
        setRoles(response.data.roles || []);
        setErrorRoles(null);
      } catch (error) {
        console.error('Ошибка при загрузке ролей:', error);
        setErrorRoles('Не удалось загрузить роли. Попробуйте позже.');
      } finally {
        setLoadingRoles(false);
      }
    };

    const fetchTeams = async () => {
      try {
        const response = await axiosInstance.get('/teams'); // Предполагается, что есть эндпоинт /teams
        setTeams(response.data.teams || []);
        setErrorTeams(null);
      } catch (error) {
        console.error('Ошибка при загрузке команд:', error);
        setErrorTeams('Не удалось загрузить команды. Попробуйте позже.');
      } finally {
        setLoadingTeams(false);
      }
    };

    if (open) {
      fetchRoles();
      fetchTeams();
    }
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRolesChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      roles: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const handleSubmit = async () => {
    // Валидация формы
    if (!formData.login || !formData.email || !formData.password || formData.roles.length === 0) {
      setErrorSubmit('Пожалуйста, заполните все обязательные поля.');
      return;
    }

    setSubmitting(true);
    setErrorSubmit(null);
    setSuccessSubmit(false);

    try {
      const payload = {
        login: formData.login,
        email: formData.email,
        password: formData.password,
        roles: formData.roles, // Предполагается, что сервер ожидает массив ролей
        team: formData.team || null, // Отправка ID команды, если выбрана
        // Офис будет определяться на сервере на основе аутентифицированного суперадмина
      };

      const response = await axiosInstance.post('/users', payload); // Предполагается, что есть эндпоинт /users

      setSuccessSubmit(true);
      setFormData({
        login: '',
        email: '',
        password: '',
        roles: [],
        team: '',
      });

      if (onUserCreated) {
        onUserCreated(response.data.user); // Предполагается, что сервер возвращает созданного пользователя
      }
    } catch (error) {
      console.error('Ошибка при добавлении пользователя:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorSubmit(error.response.data.message);
      } else {
        setErrorSubmit('Не удалось добавить пользователя. Попробуйте позже.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    setErrorSubmit(null);
    setSuccessSubmit(false);
    setFormData({
      login: '',
      email: '',
      password: '',
      roles: [],
      team: '',
    });
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Создать пользователя</DialogTitle>
      <DialogContent>
        {errorSubmit && <Alert severity="error" sx={{ mb: 2 }}>{errorSubmit}</Alert>}
        {successSubmit && <Alert severity="success" sx={{ mb: 2 }}>Пользователь успешно создан!</Alert>}
        <TextField
          autoFocus
          margin="dense"
          label="Логин"
          name="login"
          type="text"
          fullWidth
          variant="outlined"
          value={formData.login}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          label="Email"
          name="email"
          type="email"
          fullWidth
          variant="outlined"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextField
          margin="dense"
          label="Пароль"
          name="password"
          type="password"
          fullWidth
          variant="outlined"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <FormControl fullWidth margin="dense" variant="outlined" required>
          <InputLabel id="roles-label">Роли</InputLabel>
          {loadingRoles ? (
            <Box display="flex" alignItems="center" padding={2}>
              <CircularProgress size={24} />
              <Typography variant="body2" sx={{ ml: 2 }}>Загрузка ролей...</Typography>
            </Box>
          ) : errorRoles ? (
            <FormHelperText error>{errorRoles}</FormHelperText>
          ) : (
            <Select
              labelId="roles-label"
              multiple
              value={formData.roles}
              onChange={handleRolesChange}
              input={<OutlinedInput label="Роли" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.name}>
                  <Checkbox checked={formData.roles.indexOf(role.name) > -1} />
                  <ListItemText primary={role.name} />
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>

        {/* Добавление поля выбора команды */}
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel id="team-label">Команда</InputLabel>
          {loadingTeams ? (
            <Box display="flex" alignItems="center" padding={2}>
              <CircularProgress size={24} />
              <Typography variant="body2" sx={{ ml: 2 }}>Загрузка команд...</Typography>
            </Box>
          ) : errorTeams ? (
            <FormHelperText error>{errorTeams}</FormHelperText>
          ) : (
            <Select
              labelId="team-label"
              name="team"
              value={formData.team}
              onChange={handleChange}
              label="Команда"
            >
              <MenuItem value="">
                <em>Не выбрано</em>
              </MenuItem>
              {teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={submitting}>Отмена</Button>
        <Button onClick={handleSubmit} variant="contained" disabled={submitting || loadingRoles || errorRoles}>
          {submitting ? <CircularProgress size={24} /> : 'Создать'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUser;
