// src/pages/Users/FilterModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Box,
} from '@mui/material';

const FilterModal = ({ open, onClose, columns, visibleColumns, setVisibleColumns }) => {
  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    if (open) {
      const initiallySelected = columns.filter(column => visibleColumns[column]);
      setSelectedColumns(initiallySelected);
    }
  }, [open, columns, visibleColumns]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedColumns(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleApply = () => {
    const updatedVisibility = {};
    columns.forEach(column => {
      updatedVisibility[column] = selectedColumns.includes(column);
    });
    setVisibleColumns(updatedVisibility);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Фильтры</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="columns-label">Видимые столбцы</InputLabel>
          <Select
            labelId="columns-label"
            multiple
            value={selectedColumns}
            onChange={handleChange}
            input={<OutlinedInput label="Видимые столбцы" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {columns.map((column) => (
              <MenuItem key={column} value={column}>
                <Checkbox checked={selectedColumns.indexOf(column) > -1} />
                <ListItemText primary={column.charAt(0).toUpperCase() + column.slice(1)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">Отмена</Button>
        <Button onClick={handleApply} variant="contained" color="primary">Применить</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterModal;
