// src/components/Sidebar/Sidebar.js

import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
  Toolbar,
  Divider,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Assignment as AssignmentIcon,
  People as PeopleIcon,
  Settings as SettingsIcon,
  Chat as ChatIcon,
  SupportAgent as SupportAgentIcon,
  Logout as LogoutIcon,
  ExpandLess,
  ExpandMore,
  AccountCircle as AccountCircleIcon,
  Business as BusinessIcon,
  Group as GroupIcon,
  Call as CallIcon,
  PeopleAlt as PeopleAltIcon,
  Message as MessageIcon, // Импортируем иконку для AI Ассистента
} from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import './Sidebar.css'; // Убедитесь, что этот файл существует и корректен

const drawerWidth = 240; // Определение ширины сайдбара

const Sidebar = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  // Состояния для управления разворачиванием списков
  const [openAccounts, setOpenAccounts] = useState(false);
  const [openBusinessUnits, setOpenBusinessUnits] = useState(false);

  // Обработчики кликов для разворачивания/сворачивания списков
  const handleLogoutClick = () => {
    logout();
    navigate('/login');
  };

  const handleAccountsClick = () => {
    setOpenAccounts(!openAccounts);
  };

  const handleBusinessUnitsClick = () => {
    setOpenBusinessUnits(!openBusinessUnits);
  };

  // Основные пункты меню без выпадающих списков
  const menuItems = [
    { text: 'Дашборд', icon: <DashboardIcon />, path: '/dashboard' },
    { text: 'Лиды', icon: <AssignmentIcon />, path: '/leads' },
    { text: 'Пользователи', icon: <PeopleIcon />, path: '/users' },
    { text: 'Поддержка', icon: <SupportAgentIcon />, path: '/support' },
    { text: 'Настройки', icon: <SettingsIcon />, path: '/settings' },
    { text: 'Чаты', icon: <ChatIcon />, path: '/chats' },
    { text: 'AI Ассистент', icon: <MessageIcon />, path: '/ai-chat' }, // Добавляем пункт для AI Ассистента
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Divider />
      <List>
        {/* Отображение основных пунктов меню */}
        {menuItems.map((item) => (
          <ListItem
            key={item.text}
            disablePadding
            component={NavLink}
            to={item.path}
            style={({ isActive }) => ({
              backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
            })}
          >
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}

        {/* Раздел "Аккаунты" с выпадающим списком */}
        <ListItemButton onClick={handleAccountsClick}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Аккаунты" />
          {openAccounts ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openAccounts} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Подразделы Аккаунтов */}
            <ListItem
              button
              component={NavLink}
              to="/accounts/agents"
              sx={{ pl: 4 }}
              style={({ isActive }) => ({
                backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
              })}
            >
              <ListItemIcon>
                <GroupIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Агенты" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/accounts/team-leads"
              sx={{ pl: 4 }}
              style={({ isActive }) => ({
                backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
              })}
            >
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Тимлиды" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/accounts/supports"
              sx={{ pl: 4 }}
              style={({ isActive }) => ({
                backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
              })}
            >
              <ListItemIcon>
                <SupportAgentIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Сапорты" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/accounts/heads"
              sx={{ pl: 4 }}
              style={({ isActive }) => ({
                backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
              })}
            >
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Хеды" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/accounts/retainers"
              sx={{ pl: 4 }}
              style={({ isActive }) => ({
                backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
              })}
            >
              <ListItemIcon>
                <AssignmentIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Ретены" />
            </ListItem>
          </List>
        </Collapse>

        {/* Раздел "Бизнес-подразделения" с выпадающим списком */}
        <ListItemButton onClick={handleBusinessUnitsClick}>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Бизнес-подразделения" />
          {openBusinessUnits ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openBusinessUnits} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* Подразделы Бизнес-подразделений */}
            <ListItem
              button
              component={NavLink}
              to="/business-units/affiliates"
              sx={{ pl: 4 }}
              style={({ isActive }) => ({
                backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
              })}
            >
              <ListItemIcon>
                <BusinessIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Аффилиаты" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/business-units/call-centers"
              sx={{ pl: 4 }}
              style={({ isActive }) => ({
                backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
              })}
            >
              <ListItemIcon>
                <CallIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Колл-центры" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/business-units/teams"
              sx={{ pl: 4 }}
              style={({ isActive }) => ({
                backgroundColor: isActive ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
              })}
            >
              <ListItemIcon>
                <PeopleAltIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Команды" />
            </ListItem>
          </List>
        </Collapse>
      </List>
      <Divider />
      <List>
        {/* Кнопка выхода */}
        <ListItem button onClick={handleLogoutClick}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Выйти" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
