// src/pages/Chats/Chats.js
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
// Удалите или закомментируйте импорт socket
// import socket from '../../socket';

const Chats = () => {
  const [chats, setChats] = useState([]);

  useEffect(() => {
    // Ваша логика без использования socket
    // Например, загрузка чатов из API
    fetch('/api/chats')
      .then((response) => response.json())
      .then((data) => setChats(data))
      .catch((error) => console.error('Ошибка загрузки чатов:', error));
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Чаты
      </Typography>
      {/* Отображение чатов */}
      {chats.map((chat) => (
        <div key={chat.id}>
          <Typography>{chat.name}</Typography>
        </div>
      ))}
    </div>
  );
};

export default Chats;
