// src/pages/Leads/LeadDetails.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Alert,
  Tooltip,
  Tabs,
  Tab,
  Grid,
  InputAdornment,
  Select,
  MenuItem,
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  AddComment as AddCommentIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  Home as HomeIcon,
  CalendarToday as CalendarTodayIcon,
  LocationOn as LocationOnIcon,
  Description as DescriptionIcon,
} from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { motion } from 'framer-motion';
import moment from 'moment';

const LeadDetails = () => {
  const { leadId } = useParams();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [lead, setLead] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedLead, setEditedLead] = useState({});
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0); // Для управления вкладками

  useEffect(() => {
    if (!auth.user) {
      navigate('/login');
    } else {
      fetchLeadDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId, auth.user]);

  const fetchLeadDetails = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`/leads/${leadId}`);
      setLead(response.data.lead);
      setComments(response.data.lead.comments || []);
      setEditedLead(response.data.lead);
      setError(null);
    } catch (error) {
      console.error('Ошибка при загрузке деталей лида:', error);
      if (error.response && error.response.status === 401) {
        auth.logout();
        navigate('/login');
      } else if (error.response && error.response.status === 404) {
        setError('Лид не найден');
      } else {
        setError('Ошибка при загрузке данных лида');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
    if (isEditing) setEditedLead(lead); // Сбросить изменения при отмене редактирования
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedLead((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveChanges = async () => {
    try {
      await axiosInstance.put(`/leads/${leadId}`, editedLead);
      setIsEditing(false);
      fetchLeadDetails();
    } catch (error) {
      console.error('Ошибка при сохранении изменений:', error);
      setError('Ошибка при сохранении изменений');
    }
  };

  const handleDeleteLead = async () => {
    if (window.confirm('Вы уверены, что хотите удалить этого лида?')) {
      try {
        await axiosInstance.delete(`/leads/${leadId}`);
        navigate('/leads');
      } catch (error) {
        console.error('Ошибка при удалении лида:', error);
        setError('Ошибка при удалении лида');
      }
    }
  };

  const handleAddComment = async () => {
    if (newComment.trim()) {
      try {
        await axiosInstance.post(`/leads/${leadId}/comments`, { text: newComment });
        setNewComment('');
        fetchLeadDetails();
      } catch (error) {
        console.error('Ошибка при добавлении комментария:', error);
        setError('Ошибка при добавлении комментария');
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box padding={3}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!lead) {
    return (
      <Box padding={3}>
        <Alert severity="warning">Лид не найден</Alert>
      </Box>
    );
  }

  return (
    <Box padding={3} maxWidth="1200px" margin="0 auto">
      <Typography variant="h4" gutterBottom>
        Карточка лида: {lead.id}
      </Typography>

      {/* Основной макет с тремя колонками */}
      <Grid container spacing={3}>
        {/* Колонка 1: Личная информация */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
              <Typography variant="h6">Личная информация</Typography>
              <Box>
                <Tooltip title={isEditing ? "Сохранить изменения" : "Редактировать"}>
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <IconButton color="primary" onClick={isEditing ? handleSaveChanges : handleEditToggle}>
                      {isEditing ? <SaveIcon /> : <EditIcon />}
                    </IconButton>
                  </motion.div>
                </Tooltip>
                <Tooltip title="Удалить лид">
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <IconButton color="secondary" onClick={handleDeleteLead}>
                      <DeleteIcon />
                    </IconButton>
                  </motion.div>
                </Tooltip>
              </Box>
            </Box>
            <Divider sx={{ marginBottom: 2 }} />

            <Box display="flex" flexDirection="column" gap={2}>
              {/* Поля с иконками */}
              <TextField
                label="Имя"
                name="first_name"
                value={editedLead.first_name || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography component="span">
                        <Typography component="span">
                          <Box display="flex" alignItems="center">
                            <PhoneIcon />
                          </Box>
                        </Typography>
                      </Typography>
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Фамилия"
                name="surname"
                value={editedLead.surname || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Телефон"
                name="phone_number"
                value={editedLead.phone_number || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Email"
                name="email"
                value={editedLead.email || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Страна"
                name="country"
                value={editedLead.country || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Город"
                name="city"
                value={editedLead.city || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Адрес"
                name="address"
                value={editedLead.address || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HomeIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Дата рождения"
                name="date_of_birth"
                type="date"
                value={editedLead.date_of_birth ? moment(editedLead.date_of_birth).format('YYYY-MM-DD') : ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Почтовый индекс"
                name="zip_code"
                value={editedLead.zip_code || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
              <TextField
                label="Описание"
                name="description"
                value={editedLead.description || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DescriptionIcon />
                    </InputAdornment>
                  ),
                  readOnly: !isEditing,
                  style: { color: '#000' },
                }}
              />
            </Box>
          </Paper>
        </Grid>

        {/* Колонка 2: Информация о бренде и статусах */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Дополнительная информация
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Название бренда"
                name="brand_name"
                value={editedLead.brand_name || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                select
              >
                <MenuItem value="">Выберите бренд</MenuItem>
                <MenuItem value="Brand A">Brand A</MenuItem>
                <MenuItem value="Brand B">Brand B</MenuItem>
                {/* Добавьте больше брендов по необходимости */}
              </TextField>
              <TextField
                label="Дата регистрации"
                name="registration_date"
                type="date"
                value={editedLead.registration_date ? moment(editedLead.registration_date).format('YYYY-MM-DD') : ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Статус лида"
                name="lead_status"
                value={editedLead.lead_status || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                select
              >
                <MenuItem value="">Выберите статус</MenuItem>
                <MenuItem value="New">Новый</MenuItem>
                <MenuItem value="Contacted">Связан</MenuItem>
                <MenuItem value="Qualified">Квалифицирован</MenuItem>
                {/* Добавьте больше статусов по необходимости */}
              </TextField>
              <TextField
                label="Статус ретена"
                name="retention_status"
                value={editedLead.retention_status || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                select
              >
                <MenuItem value="">Выберите статус</MenuItem>
                <MenuItem value="Active">Активен</MenuItem>
                <MenuItem value="Inactive">Неактивен</MenuItem>
                {/* Добавьте больше статусов по необходимости */}
              </TextField>
              <TextField
                label="Сигналы"
                name="signals"
                value={editedLead.signals || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Дата следующего звонка"
                name="next_call_date"
                type="datetime-local"
                value={editedLead.next_call_date ? moment(editedLead.next_call_date).format('YYYY-MM-DDTHH:mm') : ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="KYC статус"
                name="kyc_status"
                value={editedLead.kyc_status || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                select
              >
                <MenuItem value="">Выберите статус</MenuItem>
                <MenuItem value="Verified">Подтвержден</MenuItem>
                <MenuItem value="Pending">В ожидании</MenuItem>
                <MenuItem value="Rejected">Отклонен</MenuItem>
              </TextField>
              <TextField
                label="Email подтвержден?"
                name="email_verified"
                value={editedLead.email_verified ? 'Да' : 'Нет'}
                onChange={(e) => setEditedLead((prev) => ({ ...prev, email_verified: e.target.value === 'Да' }))}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                select
              >
                <MenuItem value="">Выберите статус</MenuItem>
                <MenuItem value="Да">Да</MenuItem>
                <MenuItem value="Нет">Нет</MenuItem>
              </TextField>
            </Box>
          </Paper>
        </Grid>

        {/* Колонка 3: Информация о лиде и параметры лояльности */}
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            <Typography variant="h6" gutterBottom>
              Информация о лиде
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="ID лида"
                name="lead_id"
                value={lead.id}
                disabled
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Тип аккаунта"
                name="account_type"
                value={editedLead.account_type || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                select
              >
                <MenuItem value="">Выберите тип</MenuItem>
                <MenuItem value="Individual">Физическое лицо</MenuItem>
                <MenuItem value="Company">Компания</MenuItem>
              </TextField>
              <TextField
                label="Агент"
                name="agent"
                value={editedLead.agent?.username || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Последний агент лида"
                name="last_agent"
                value={editedLead.last_agent?.username || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Дата последнего касания"
                name="last_touch_date"
                type="datetime-local"
                value={editedLead.last_touch_date ? moment(editedLead.last_touch_date).format('YYYY-MM-DDTHH:mm') : ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Аффилиат"
                name="affiliate"
                value={editedLead.affiliate?.name || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Источник"
                name="source"
                value={editedLead.source || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Кампания"
                name="campaign"
                value={editedLead.campaign || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Торговая группа"
                name="trade_group"
                value={editedLead.trade_group || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
              />
              <TextField
                label="Ярлыки клиента"
                name="labels"
                value={editedLead.labels || ''}
                onChange={handleInputChange}
                disabled={!isEditing}
                variant="outlined"
                fullWidth
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Вкладки для дополнительных данных */}
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Комментарии" />
          <Tab label="Финансы" />
          <Tab label="Торговые счета" />
          <Tab label="Документы" />
          <Tab label="История" />
          <Tab label="Звонки" />
          <Tab label="Чат" />
          <Tab label="Сигналы" />
          <Tab label="Активные сессии" />
        </Tabs>
        <Divider sx={{ marginY: 2 }} />

        {/* Содержимое вкладок */}
        {activeTab === 0 && (
          <Box>
            {/* Комментарии */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                <Typography variant="h6">Комментарии</Typography>
              </Box>
              <Divider sx={{ marginBottom: 2 }} />
              <List>
                {comments.length > 0 ? (
                  comments.map((comment, index) => (
                    <ListItem key={index} alignItems="flex-start">
                      <ListItemText
                        primary={
                          <Typography variant="subtitle2" color="textPrimary">
                            {comment.creator?.username || 'Неизвестный пользователь'}
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography variant="body2" color="textPrimary">
                              {comment.text}
                            </Typography>
                            <Typography variant="caption" color="textSecondary">
                              {moment(comment.created_at).format('DD.MM.YYYY HH:mm')}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">Нет комментариев</Typography>
                )}
              </List>

              <Divider sx={{ marginY: 2 }} />

              <Box display="flex" alignItems="center" gap={2}>
                <TextField
                  label="Новый комментарий"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  InputProps={{
                    style: { color: '#000' },
                  }}
                />
                <Tooltip title="Добавить комментарий">
                  <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <IconButton color="primary" onClick={handleAddComment}>
                      <AddCommentIcon />
                    </IconButton>
                  </motion.div>
                </Tooltip>
              </Box>
            </Paper>
          </Box>
        )}

        {activeTab === 1 && (
          <Box>
            {/* Финансовая информация */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
              <Typography variant="h6" gutterBottom>Финансовая информация</Typography>
              <Divider sx={{ marginBottom: 2 }} />
              {/* Здесь добавьте компоненты для отображения финансовой информации */}
              <Typography>Раздел в разработке</Typography>
            </Paper>
          </Box>
        )}

        {activeTab === 2 && (
          <Box>
            {/* Торговые счета */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
              <Typography variant="h6" gutterBottom>Торговые счета</Typography>
              <Divider sx={{ marginBottom: 2 }} />
              {/* Здесь добавьте компоненты для отображения торговых счетов */}
              <Typography>Раздел в разработке</Typography>
            </Paper>
          </Box>
        )}

        {activeTab === 3 && (
          <Box>
            {/* Документы */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
              <Typography variant="h6" gutterBottom>Документы</Typography>
              <Divider sx={{ marginBottom: 2 }} />
              {/* Здесь добавьте компоненты для загрузки и отображения документов */}
              <Typography>Раздел в разработке</Typography>
            </Paper>
          </Box>
        )}

        {activeTab === 4 && (
          <Box>
            {/* История действий */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
              <Typography variant="h6" gutterBottom>История действий</Typography>
              <Divider sx={{ marginBottom: 2 }} />
              {/* Здесь добавьте компоненты для отображения истории действий */}
              <Typography>Раздел в разработке</Typography>
            </Paper>
          </Box>
        )}

        {activeTab === 5 && (
          <Box>
            {/* Звонки */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
              <Typography variant="h6" gutterBottom>Звонки</Typography>
              <Divider sx={{ marginBottom: 2 }} />
              {/* Здесь добавьте компоненты для управления звонками */}
              <Typography>Раздел в разработке</Typography>
            </Paper>
          </Box>
        )}

        {activeTab === 6 && (
          <Box>
            {/* Чат */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
              <Typography variant="h6" gutterBottom>Чат</Typography>
              <Divider sx={{ marginBottom: 2 }} />
              {/* Здесь добавьте компоненты для чата */}
              <Typography>Раздел в разработке</Typography>
            </Paper>
          </Box>
        )}

        {activeTab === 7 && (
          <Box>
            {/* Сигналы */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
              <Typography variant="h6" gutterBottom>Сигналы</Typography>
              <Divider sx={{ marginBottom: 2 }} />
              {/* Здесь добавьте компоненты для отображения сигналов */}
              <Typography>Раздел в разработке</Typography>
            </Paper>
          </Box>
        )}

        {activeTab === 8 && (
          <Box>
            {/* Активные сессии */}
            <Paper elevation={3} sx={{ padding: 3, marginBottom: 4 }}>
              <Typography variant="h6" gutterBottom>Активные сессии</Typography>
              <Divider sx={{ marginBottom: 2 }} />
              {/* Здесь добавьте компоненты для отображения активных сессий */}
              <Typography>Раздел в разработке</Typography>
            </Paper>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default LeadDetails;
