// src/components/LanguageSwitcher.js
import React from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const LanguageSwitcher = ({ changeLanguage }) => (
  <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
    <InputLabel id="language-select-label">Language</InputLabel>
    <Select
      labelId="language-select-label"
      defaultValue="en"
      onChange={(e) => changeLanguage(e.target.value)}
      label="Language"
    >
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="ru">Русский</MenuItem>
    </Select>
  </FormControl>
);

export default LanguageSwitcher;
