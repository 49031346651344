// src/pages/BusinessUnits/CallCenters.js

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig'; // Настроенный axios для автоматической передачи токена
import AddCallCenterModal from '../../components/AddCallCenterModal';
import EditCallCenterModal from '../../components/EditCallCenterModal';
import CallCenterModal from '../../components/CallCenterModal';

const CallCenters = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [callCenters, setCallCenters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Состояния для управления модальными окнами и выбранного колл-центра
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedCallCenter, setSelectedCallCenter] = useState(null);

  // Проверка токена, загрузка данных колл-центров
  useEffect(() => {
    if (!user?.token) {
      navigate('/login');
    } else {
      const fetchCallCenters = async () => {
        try {
          const response = await axiosInstance.get('/business-units/call-centers');
          if (Array.isArray(response.data.callCenters)) {
            setCallCenters(response.data.callCenters);
          } else {
            throw new Error('Неправильный формат ответа от сервера');
          }
        } catch (err) {
          console.error('Ошибка загрузки колл-центров:', err);
          setError('Ошибка загрузки колл-центров. Попробуйте позже.');
          if (err.response && err.response.status === 401) {
            navigate('/login');
          }
        } finally {
          setLoading(false);
        }
      };
      fetchCallCenters();
    }
  }, [user, navigate]);

  // Обработчики для модальных окон
  const handleOpenAddModal = () => setIsAddModalOpen(true);
  const handleCloseAddModal = () => setIsAddModalOpen(false);

  const handleOpenEditModal = (callCenter) => {
    setSelectedCallCenter(callCenter);
    setIsEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setSelectedCallCenter(null);
    setIsEditModalOpen(false);
  };

  const handleOpenViewModal = (callCenter) => {
    setSelectedCallCenter(callCenter);
    setIsViewModalOpen(true);
  };
  const handleCloseViewModal = () => {
    setSelectedCallCenter(null);
    setIsViewModalOpen(false);
  };

  // Обработка добавления колл-центра
  const handleAddCallCenter = async (newCallCenter) => {
    try {
      const response = await axiosInstance.post('/business-units/call-centers', newCallCenter);
      setCallCenters((prev) => [...prev, { id: response.data.callCenterId, ...newCallCenter }]);
      handleCloseAddModal();
    } catch (error) {
      console.error('Ошибка добавления колл-центра:', error);
    }
  };

  // Обработка обновления колл-центра
  const handleUpdateCallCenter = async (updatedCallCenter) => {
    try {
      await axiosInstance.put(`/business-units/call-centers/${updatedCallCenter.id}`, updatedCallCenter);
      setCallCenters((prev) =>
        prev.map((cc) => (cc.id === updatedCallCenter.id ? updatedCallCenter : cc))
      );
      handleCloseEditModal();
    } catch (error) {
      console.error('Ошибка обновления колл-центра:', error);
    }
  };

  // Обработка удаления колл-центра
  const handleDeleteCallCenter = async (callCenterId) => {
    try {
      await axiosInstance.delete(`/business-units/call-centers/${callCenterId}`);
      setCallCenters((prev) => prev.filter((cc) => cc.id !== callCenterId));
    } catch (error) {
      console.error('Ошибка удаления колл-центра:', error);
    }
  };

  // Отображение состояния загрузки
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  // Отображение ошибки
  if (error) {
    return (
      <Box>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Колл-центры
      </Typography>
      {user && user.role === 'superadmin' && (
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
          sx={{ mb: 2 }}
        >
          Добавить колл-центр
        </Button>
      )}
      {callCenters.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell align="right">Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {callCenters.map((callCenter) => (
                <TableRow
                  key={callCenter.id}
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpenViewModal(callCenter)}
                >
                  <TableCell>{callCenter.name}</TableCell>
                  <TableCell align="right">
                    {user && user.role === 'superadmin' && (
                      <>
                        <Tooltip title="Редактировать">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenEditModal(callCenter);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Удалить">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteCallCenter(callCenter.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">Нет колл-центров для отображения.</Typography>
      )}

      {/* Модальные окна */}
      <AddCallCenterModal
        open={isAddModalOpen}
        handleClose={handleCloseAddModal}
        handleAddCallCenter={handleAddCallCenter}
      />
      {selectedCallCenter && (
        <>
          <EditCallCenterModal
            open={isEditModalOpen}
            handleClose={handleCloseEditModal}
            callCenter={selectedCallCenter}
            handleUpdateCallCenter={handleUpdateCallCenter}
          />
          <CallCenterModal
            open={isViewModalOpen}
            handleClose={handleCloseViewModal}
            callCenter={selectedCallCenter}
          />
        </>
      )}
    </Box>
  );
};

export default CallCenters;
