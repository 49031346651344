// src/components/ThemeSwitcher.js
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';

const ThemeSwitcher = ({ toggleTheme, themeMode }) => (
  <Tooltip title="Toggle theme">
    <IconButton onClick={toggleTheme} color="inherit">
      {themeMode === 'light' ? <Brightness7 /> : <Brightness4 />}
    </IconButton>
  </Tooltip>
);

export default ThemeSwitcher;
