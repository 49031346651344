// src/components/EditAffiliateModal.js

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Stack,
  Alert,
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const EditAffiliateModal = ({ open, handleClose, affiliate, handleUpdate }) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    middle_name: '',
    phone: '',
    email: '',
    country: '',
    daily_lead_limit: '',
    whitelisted_ip: '',
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    if (affiliate) {
      setFormData({
        first_name: affiliate.first_name || '',
        last_name: affiliate.last_name || '',
        middle_name: affiliate.middle_name || '',
        phone: affiliate.phone || '',
        email: affiliate.email || '',
        country: affiliate.country || '',
        daily_lead_limit: affiliate.daily_lead_limit || 100,
        whitelisted_ip: affiliate.whitelisted_ip ? affiliate.whitelisted_ip.join(', ') : '',
      });
    }
  }, [affiliate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    // Простая валидация
    const { first_name, last_name, phone, email, country } = formData;
    if (!first_name || !last_name || !phone || !email || !country) {
      setError('Пожалуйста, заполните все обязательные поля');
      return;
    }

    const updatedData = {
      ...formData,
      daily_lead_limit: parseInt(formData.daily_lead_limit, 10) || 100,
      whitelisted_ip: formData.whitelisted_ip
        ? formData.whitelisted_ip.split(',').map(ip => ip.trim())
        : [],
    };

    handleUpdate(affiliate.id, updatedData);
    setError(null);
  };

  if (!affiliate) return null;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-affiliate-title"
    >
      <Box sx={style}>
        <Typography id="edit-affiliate-title" variant="h6" component="h2" gutterBottom>
          Редактировать аффилиата
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <Stack spacing={2}>
          <TextField
            label="Имя"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Фамилия"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Отчество"
            name="middle_name"
            value={formData.middle_name}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Телефон"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Страна"
            name="country"
            value={formData.country}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Лимит лидов в день"
            name="daily_lead_limit"
            type="number"
            value={formData.daily_lead_limit}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            label="Белый список IP (через запятую)"
            name="whitelisted_ip"
            value={formData.whitelisted_ip}
            onChange={handleChange}
            fullWidth
          />
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Сохранить
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default EditAffiliateModal;
