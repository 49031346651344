// src/pages/Accounts/Supports.js

import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress, Alert, List, ListItem, ListItemText, Button } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig'; // Используем настроенный axiosInstance

const Supports = () => {
  const { auth } = useAuth(); // Получаем объект auth из контекста
  const navigate = useNavigate();
  const [supports, setSupports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Функция для загрузки данных сапортов
    const fetchSupports = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get('/accounts/supports'); // Токен автоматически добавляется
        setSupports(response.data.supports);
      } catch (err) {
        console.error('Ошибка загрузки сапортов:', err);
        if (err.response && err.response.status === 401) {
          // Если токен истёк или невалиден, вызываем logout и перенаправляем на логин
          auth.logout();
          navigate('/login');
        } else {
          setError('Ошибка загрузки сапортов. Попробуйте позже.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSupports();
  }, [auth, navigate]);

  // Отображение состояния загрузки
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  // Отображение ошибки
  if (error) {
    return (
      <Box>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Сапорты
      </Typography>
      {supports.length > 0 ? (
        <List>
          {supports.map((support) => (
            <ListItem key={support.id}>
              <ListItemText primary={support.name} secondary={support.email} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography variant="body1">Нет сапортов для отображения.</Typography>
      )}
      {/* Кнопка для добавления нового сапорта доступна только для администраторов */}
      {auth.user?.roles.includes('admin') && (
        <Button
          variant="contained"
          onClick={() => navigate('/accounts/supports/add')}
          sx={{ mt: 2 }}
        >
          Добавить сапорта
        </Button>
      )}
    </Box>
  );
};

export default Supports;
