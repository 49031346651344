// src/pages/Users/ImportUsersModal.js

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import axiosInstance from '../../utils/axiosConfig';

const ImportUsersModal = ({ open, onClose, onImportSuccess }) => {
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState(null);
  const [successSubmit, setSuccessSubmit] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!file) {
      setErrorSubmit('Пожалуйста, выберите файл для импорта.');
      return;
    }

    setSubmitting(true);
    setErrorSubmit(null);
    setSuccessSubmit(false);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axiosInstance.post('/users/import', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setSuccessSubmit(true);
      setFile(null);
      if (onImportSuccess) {
        onImportSuccess();
      }
    } catch (error) {
      console.error('Ошибка при импорте пользователей:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorSubmit(error.response.data.message);
      } else {
        setErrorSubmit('Не удалось импортировать пользователей. Попробуйте позже.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleClose = () => {
    setErrorSubmit(null);
    setSuccessSubmit(false);
    setFile(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Импорт пользователей из Excel</DialogTitle>
      <DialogContent>
        {errorSubmit && <Alert severity="error" sx={{ mb: 2 }}>{errorSubmit}</Alert>}
        {successSubmit && <Alert severity="success" sx={{ mb: 2 }}>Пользователи успешно импортированы!</Alert>}
        <Box mt={2}>
          <input
            accept=".xlsx,.xls"
            style={{ display: 'none' }}
            id="import-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="import-file">
            <Button variant="contained" component="span">
              Выбрать файл
            </Button>
          </label>
          {file && (
            <Typography variant="body1" sx={{ mt: 1 }}>
              Выбранный файл: {file.name}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" disabled={submitting}>Отмена</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={submitting}>
          {submitting ? <CircularProgress size={24} /> : 'Импортировать'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportUsersModal;
