// src/pages/BusinessUnits/Affiliates.js

import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Alert,
  Button,
  Grid,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import AffiliateCard from '../../components/AffiliateCard';
import AddAffiliateModal from '../../components/AddAffiliateModal';
import EditAffiliateModal from '../../components/EditAffiliateModal';
import AffiliateDetailsModal from '../../components/AffiliateDetailsModal';
import AdvancedSettingsModal from '../../components/AdvancedSettingsModal';

const Affiliates = () => {
  const { token, user } = useAuth();
  const navigate = useNavigate();
  const [affiliates, setAffiliates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Состояния для модальных окон
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false);
  const [selectedAffiliate, setSelectedAffiliate] = useState(null);

  useEffect(() => {
    if (!token) {
      navigate('/login');
      return;
    }

    if (!user) {
      // Ждём, пока пользователь загрузится
      return;
    }

    console.log('Текущий пользователь:', user); // Добавлено

    const fetchAffiliates = async () => {
      try {
        const response = await axios.get('/business-units/affiliates', {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log('Данные ответа:', response.data);

        if (Array.isArray(response.data.affiliates)) {
          setAffiliates(response.data.affiliates);
        } else {
          console.error('Непредвиденный формат данных:', response.data);
          setError('Ошибка: получен некорректный формат данных');
        }
      } catch (err) {
        console.error('Ошибка загрузки аффилиатов:', err);
        setError('Ошибка загрузки аффилиатов. Попробуйте позже.');
      } finally {
        setLoading(false);
      }
    };

    fetchAffiliates();
  }, [token, user, navigate]);

  // Обработчики для модальных окон
  const handleOpenAddModal = () => {
    console.log('Открытие модального окна добавления');
    setIsAddModalOpen(true);
  };
  const handleCloseAddModal = () => setIsAddModalOpen(false);

  const handleOpenEditModal = (affiliate) => {
    console.log('Открытие модального окна редактирования для:', affiliate.id);
    setSelectedAffiliate(affiliate);
    setIsEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setSelectedAffiliate(null);
    setIsEditModalOpen(false);
  };

  const handleOpenViewModal = (affiliate) => {
    console.log('Открытие модального окна просмотра для:', affiliate.id);
    setSelectedAffiliate(affiliate);
    setIsViewModalOpen(true);
  };
  const handleCloseViewModal = () => {
    setSelectedAffiliate(null);
    setIsViewModalOpen(false);
  };

  const handleOpenAdvancedSettings = (affiliate) => {
    console.log('Открытие модального окна расширенных настроек для:', affiliate.id);
    setSelectedAffiliate(affiliate);
    setIsAdvancedSettingsOpen(true);
  };
  const handleCloseAdvancedSettings = () => {
    setSelectedAffiliate(null);
    setIsAdvancedSettingsOpen(false);
  };

  // Обработчики для добавления, обновления и удаления аффилиатов
  const handleAddAffiliate = async (newAffiliate) => {
    try {
      const response = await axios.post('/business-units/affiliates', newAffiliate, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAffiliates((prev) => [...prev, response.data.affiliate]);
      handleCloseAddModal();
    } catch (error) {
      console.error('Ошибка добавления аффилиата:', error);
      setError('Ошибка добавления аффилиата. Попробуйте позже.');
    }
  };

  const handleUpdateAffiliate = async (id, updatedData) => {
    try {
      const response = await axios.put(`/business-units/affiliates/${id}`, updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAffiliates((prev) =>
        prev.map((affiliate) => (affiliate.id === id ? response.data.affiliate : affiliate))
      );
      handleCloseEditModal();
    } catch (error) {
      console.error('Ошибка обновления аффилиата:', error);
      setError('Ошибка обновления аффилиата. Попробуйте позже.');
    }
  };

  const handleDeleteAffiliate = async (id) => {
    try {
      await axios.delete(`/business-units/affiliates/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAffiliates((prev) => prev.filter((affiliate) => affiliate.id !== id));
    } catch (error) {
      console.error('Ошибка удаления аффилиата:', error);
      setError('Ошибка удаления аффилиата. Попробуйте позже.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Аффилиаты
      </Typography>
      {user && (user.role === 'admin' || user.role === 'superadmin') && ( // Убедитесь, что роль имеет право добавлять аффилиатов
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
          sx={{ mb: 2 }}
        >
          Добавить аффилиата
        </Button>
      )}
      {affiliates.length > 0 ? (
        <Grid container spacing={2}>
          {affiliates.map((affiliate) => (
            <Grid item xs={12} sm={6} md={4} key={affiliate.id}>
              <AffiliateCard
                affiliate={affiliate}
                onEdit={handleOpenEditModal}
                onDelete={handleDeleteAffiliate}
                onView={handleOpenViewModal}
                onAdvancedSettings={handleOpenAdvancedSettings}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1">Нет аффилиатов для отображения.</Typography>
      )}

      {/* Модальные окна */}
      <AddAffiliateModal
        open={isAddModalOpen}
        handleClose={handleCloseAddModal}
        handleAdd={handleAddAffiliate}
      />
      {selectedAffiliate && (
        <>
          <EditAffiliateModal
            open={isEditModalOpen}
            handleClose={handleCloseEditModal}
            affiliate={selectedAffiliate}
            handleUpdate={handleUpdateAffiliate}
          />
          <AffiliateDetailsModal
            open={isViewModalOpen}
            handleClose={handleCloseViewModal}
            affiliate={selectedAffiliate}
          />
          <AdvancedSettingsModal
            open={isAdvancedSettingsOpen}
            handleClose={handleCloseAdvancedSettings}
            affiliate={selectedAffiliate}
            handleUpdate={handleUpdateAffiliate}
          />
        </>
      )}
    </Box>
  );
};

export default Affiliates;
