// src/pages/Accounts/components/AddUsersToTeamModal.js

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import axios from '../api/axios';

const AddUsersToTeamModal = ({ open, handleClose, handleAddUsers, existingUsers }) => {
  const { token } = useAuth();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/users', {
          headers: { Authorization: `Bearer ${token}` },
        });
        // Исключаем пользователей, уже находящихся в команде
        const availableUsers = response.data.users.filter(
          (user) => !existingUsers.some((u) => u.id === user.id)
        );
        setUsers(availableUsers);
      } catch (error) {
        console.error('Ошибка загрузки пользователей:', error);
      }
    };

    if (open) {
      fetchUsers();
    }
  }, [token, open, existingUsers]);

  const handleToggle = (userId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const handleSubmit = () => {
    handleAddUsers(selectedUsers);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...modalStyle, width: 500 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Добавить пользователей в команду
        </Typography>
        <List>
          {users.map((user) => (
            <ListItem key={user.id} button onClick={() => handleToggle(user.id)}>
              <Checkbox checked={selectedUsers.includes(user.id)} />
              <ListItemText primary={user.username} secondary={user.email} />
            </ListItem>
          ))}
        </List>
        <Button variant="contained" onClick={handleSubmit} sx={{ mt: 2 }}>
          Добавить
        </Button>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  // Такой же стиль, как в предыдущих модальных окнах
};

export default AddUsersToTeamModal;
