// src/components/StatisticCard.js

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const StatisticCard = ({
  title = 'Заголовок по умолчанию',
  value = 0,
  description = '',
}) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="h2">{value}</Typography>
        {description && <Typography variant="body2">{description}</Typography>}
      </CardContent>
    </Card>
  );
};

export default StatisticCard;
