// src/pages/Dashboard/Dashboard.js

import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, CircularProgress, Alert } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CommentIcon from '@mui/icons-material/Comment';
import HistoryIcon from '@mui/icons-material/History';
import StatisticCard from '../../components/StatisticCard/StatisticCard';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosConfig';

const Dashboard = () => {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const [recentLeads, setRecentLeads] = useState([]);
  const [recentComments, setRecentComments] = useState([]);
  const [leadActions, setLeadActions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setLoading(true);
        setError(false);

        const [leadsResponse, commentsResponse, actionsResponse] = await Promise.all([
          axiosInstance.get('/leads/recent'),
          axiosInstance.get('/leads/recent-comments'),
          axiosInstance.get('/leads/latest-actions'),
        ]);

        setRecentLeads(leadsResponse.data.recentLeads);
        setRecentComments(commentsResponse.data.recentComments);
        setLeadActions(actionsResponse.data.actions);
      } catch (err) {
        console.error('Ошибка загрузки данных дашборда:', err);
        if (err.response && err.response.status === 401) {
          logout(); // Автоматический выход при истечении сессии
          navigate('/login');
        } else {
          setError(true);
        }
      } finally {
        setLoading(false);
      }
    };

    if (auth.token) {
      fetchDashboardData();
    } else {
      navigate('/login'); // Переход на логин, если нет токена
    }
  }, [auth, navigate, logout]);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Дашборд
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StatisticCard
            title="Последние лиды"
            value={recentLeads.length}
            icon={<PersonAddIcon fontSize="large" color="primary" />}
          />
          <Box mt={2}>
            {recentLeads.length > 0 ? (
              recentLeads.slice(0, 5).map((lead, index) => (
                <Box key={index} mb={1}>
                  <Typography variant="body2">
                    <strong>{lead.first_name} {lead.surname}</strong> - {lead.country}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2">Нет доступных лидов</Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <StatisticCard
            title="Последние комментарии"
            value={recentComments.length}
            icon={<CommentIcon fontSize="large" color="secondary" />}
          />
          <Box mt={2}>
            {recentComments.length > 0 ? (
              recentComments.slice(0, 5).map((comment, index) => (
                <Box key={index} mb={1}>
                  <Typography variant="body2">
                    <strong>Лид {comment.lead_id}:</strong> {comment.text}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2">Нет новых комментариев</Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <StatisticCard
            title="Последние действия с лидами"
            value={leadActions.length}
            icon={<HistoryIcon fontSize="large" color="success" />}
          />
          <Box mt={2}>
            {leadActions.length > 0 ? (
              leadActions.slice(0, 5).map((action, index) => (
                <Box key={index} mb={1}>
                  <Typography variant="body2">
                    <strong>Лид {action.lead_id}:</strong> {action.action} ({new Date(action.timestamp).toLocaleTimeString()})
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2">Нет действий с лидами</Typography>
            )}
          </Box>
        </Grid>
      </Grid>

      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box mt={4}>
          <Alert severity="error">Ошибка загрузки данных. Попробуйте позже.</Alert>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
