// src/pages/BusinessUnits/components/EditTeamModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Autocomplete,
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import axios from '../api/axios';

const EditTeamModal = ({ open, handleClose, team, handleUpdateTeam }) => {
  const { token } = useAuth();
  const [updatedTeam, setUpdatedTeam] = useState({
    id: '',
    name: '',
    leaderId: null,
    description: '',
  });
  const [errors, setErrors] = useState({});
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (open) {
      const fetchUsers = async () => {
        try {
          const response = await axios.get('/users', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUsers(response.data.users);
        } catch (error) {
          console.error('Ошибка загрузки пользователей:', error);
        }
      };
      fetchUsers();
    }
  }, [open, token]);

  useEffect(() => {
    if (team) {
      setUpdatedTeam({
        id: team.id,
        name: team.name,
        leaderId: team.leaderId || null,
        description: team.description,
      });
    }
  }, [team]);

  const handleChange = (e) => {
    setUpdatedTeam((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors((prev) => ({ ...prev, [e.target.name]: '' }));
  };

  const handleLeaderChange = (event, value) => {
    setUpdatedTeam((prev) => ({ ...prev, leaderId: value ? value.id : null }));
    setErrors((prev) => ({ ...prev, leaderId: '' }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!updatedTeam.name) tempErrors.name = 'Введите название команды';
    if (!updatedTeam.leaderId) tempErrors.leaderId = 'Выберите лидера команды';
    if (!updatedTeam.description)
      tempErrors.description = 'Введите описание команды';
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      handleUpdateTeam(updatedTeam);
      handleClose();
    }
  };

  if (!team) return null;

  // Находим выбранного лидера в списке пользователей
  const selectedLeader =
    users.find((user) => user.id === updatedTeam.leaderId) || null;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Редактировать команду</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              label="Название команды"
              name="name"
              value={updatedTeam.name}
              onChange={handleChange}
              fullWidth
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={users}
              getOptionLabel={(option) => option.username}
              value={selectedLeader}
              onChange={handleLeaderChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Лидер команды"
                  error={!!errors.leaderId}
                  helperText={errors.leaderId}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Описание"
              name="description"
              value={updatedTeam.description}
              onChange={handleChange}
              fullWidth
              multiline
              rows={4}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Отмена
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Сохранить изменения
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTeamModal;
